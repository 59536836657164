import React from "react";
import { HiMenu } from 'react-icons/hi'
import { AiFillCloseCircle } from 'react-icons/ai'
import { Link, Route, Routes, useNavigate} from 'react-router-dom'
import Sidebar from "../component/Sidebar";
import UserProfile from "../component/UserProfile";
import { client } from '../client'
import Pins from "./Pins"
import { useState, useRef, useEffect } from "react";
import logo from '../assets/logo.png'
import { userQuery } from "../utils/data";
import { fetchUser } from "../utils/fetchUser";
import { IoMdAdd, IoMdSearch } from "react-icons/io";
import Feed from "../component/Feed";
import Navbar from "../component/Navbar";
import "../component/SplitScreen.css"
import Footer from "../component/Footer";
import "../component/SplitScreen.css"
import NavbarFooter from "../component/NavbarFooter";
import MobileNavbar from "../component/MobileNavbar";
import M from 'materialize-css'

const Home = ({searchTerm, setSearchTerm}) => {

    const [toggleSidebar, setToggleSidebar] = useState(false)
    
    const [user, setUser] = useState()
    const scrollRef = useRef(null)
    const navigate = useNavigate()
    const userInfo = fetchUser();

    useEffect(() => {
        const query = userQuery(userInfo?.sub)

        client.fetch(query)
            .then((data) => {
                setUser(data[0])
            })
    }, []);

    // useEffect(() => {
    //     scrollRef.current.scrollTo(0, 0)
    // });

    return (
    <>
        <div className="bg-gray-50 flex hidden md:block">
                <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} user={user && user} />
        </div>
        <div className="flex bg-gray-50">
            <MobileNavbar />
        </div>
        <div className="min-h-screen flex ">
        {/* <div className="flex hidden md:block">
                <Sidebar />
            </div> */}
            <div className="flex-1">
        <div className="flex gap-2 md:gap-5 w-full mt-5 pb-7 pl-6">
            <div className="flex justify-start items-center w-full px-2 rounded-md bg-white border-none outline-none focus-within:shadow-sm">
                <IoMdSearch fontSize={21} className="ml-1"/>
                <input 
                    type="text"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search"
                    value={searchTerm}
                    onFocus={() => navigate('/search')}
                    className="p-2 w-full bg-white outline-none"
                />
            </div>
            <div className="flex gap-3 pr-3">
                <Link to='/create-pin' className="bg-black text-white rounded-lg w-12 h-12 md:w-14 md:h-12 flex justify-center items-center">
                    <IoMdAdd />
                </Link>
                
            </div>
        </div>
        {/* <div className="flex bg-gray-50 md:flex-row flex-col h-screen transition-height duration-75 ease-out">
            <div className="hidden md:flex h-screen flex-initial">
                <Sidebar user={user && user}/>
            </div>
            <div className="flex md:hidden flex-row">
                <div className="p-2 w-full flex flex-row justify-between items-center shadow-md">
                    <HiMenu fontSize={40} className="cursor-pointer" onClick={() => setToggleSidebar(true)}/>
                    <Link to="/">
                        <img src={logo} alt="logo" className="w-28"/>
                    </Link>
                    <Link to={`user-profile/${user?._id}`}>
                        <img src={user?.image} alt="user-pic" className="w-28"/>
                    </Link>
                </div>
                {toggleSidebar && (
                    <div className="fixed w-4/5 bg-white h-screen overflow-y-auto shadow-md z-10 animate-slide-in">
                        <div className="absolute w-full flex justify-end items-center p-2">
                            <AiFillCloseCircle fontSize={30} className="cursor-pointer" onClick={() => setToggleSidebar(false) }/>
                        </div>
                            <Sidebar user={user && user} closeToggle={setToggleSidebar}/>   
                    </div>
                )}
            </div>
            
            <div className="pb-2 flex-1 h-screen overflow-y-scroll" ref={scrollRef}>
                <Routes>
                    <Route path="/user-profile/:userId" element={<UserProfile />} />
                    <Route path="/" element={<Pins user={user && user} />} />
                </Routes>
            </div>   
        </div> */}
        
            
                <div className="pl-6 overflow">
                    <Feed />
                </div> 
                {/* <div style={{bottom: '0'}}><Footer /></div> */}
                
                
            </div>
        </div>
        <div className="w-screen" style={{position:"fixed", bottom:"0"}}><NavbarFooter /></div>
        </>
    )
}

export default Home