import React from "react";

const MobileNavbar = () => {

    return(
        <div className="flex bg-slate-700 shadow-lg justify-center items-center w-screen pt-5 pb-5 text-white text-center lg:hidden md:hidden">
               
                    <div className="text-3xl flex justify-center  font-sans text-center items-center">SuperCloset</div>         
                
        </div>
    )
}

export default MobileNavbar