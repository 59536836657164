import React, {useState} from "react"
import {Link, useNavigate} from 'react-router-dom'
import { IoMdAdd, IoMdSearch} from 'react-icons/io'
import Dropdown from "./Dropdown"


const Navbar = ({searchTerm, setSearchTerm, user}) => {

    const [openProfile, setOpenProfile] = useState(false)
    const navigate = useNavigate();

    if(!user) return null;

    return (
        <div className="flex bg-slate-700 shadow-lg flex-row">
               
                <div className=" flex pl-10 gap-10 md:gap-10 w-full pb-7 pt-7 text-center items-center text-white ">
                    <h1 className="text-3xl font-sans">SuperCloset</h1>
                    <Link to='/looks'>Home</Link>
                    <Link to={`/home/virtual-closet/${user?._id}` }>Virtual Closet</Link>
                    <Link to={`/home/weekly-planner/${user?._id}` }>Weekly Planner</Link>
                    <Link to='/home'>Social Media</Link>           
                </div>
                 <div  className=" pt-5 pr-3 hidden md:block text-center items-center "  >
                        <img src={user?.image} alt="user" className="w-12 h-12  rounded-full hover:cursor-pointer "  onClick={() => navigate(`/home/user-profile/${user?._id}`)}/>
                </div>
                {/* <div>
                    <img src={user?.image} className="w-14 h-12 rounded-lg hover:cursor-pointer " onClick={() => setOpenProfile((prev) => !prev)} />
                </div>
                {
                    openProfile  && <Dropdown />
                }               */}
        </div>
    )
}

export default Navbar