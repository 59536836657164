import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import Pins from './container/Pins';
import Sidebar from './component/Sidebar';
import './index.css'
import { GoogleOAuthProvider } from '@react-oauth/google';
import Navbar from './component/Navbar'
import Footer from './component/Footer';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_API_TOKEN}`}>
  // <React.StrictMode>
    <Router>
        <Navbar />
        <App />
        {/* <Footer/> */}
        {/* <Pins /> */}
    </Router>,    
  {/* </React.StrictMode>
  </GoogleOAuthProvider>   */}
);