import React, { useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { client, urlFor } from '../client'
import { v4 as uuidv4 } from 'uuid'
import {MdDownloadForOffline} from 'react-icons/md'
import {AiTwotoneDelete, AiFillHeart, AiOutlineHeart, AiFillAlert, AiOutlineAlert} from 'react-icons/ai'
import {BsFillArrowUpRightCircleFill, BsBookmark, BsFillBookmarkFill} from 'react-icons/bs'
import {TbCircleLetterM, TbCircleLetterT, TbCircleLetterW, TbCircleLetterF, TbCircleLetterS, TbSquareRoundedLetterM, TbSquareRoundedLetterT, TbSquareRoundedLetterW, TbSquareRoundedLetterF, TbSquareRoundedLetterS} from 'react-icons/tb'
import { fetchUser } from '../utils/fetchUser'

const Pin = ({pin: {postedBy, image, _id, save, monday, tuesday, wednesday, thursday, friday, saturday, sunday}}) => {
    const [postHovered, setPostHovered] = useState(false)
    const [savingPost, setSavingPost] = useState(false)
    const [mondayPost, setMondayPost] = useState(false)
    const [tuesdayPost, setTuesdayPost] = useState(false)
    const [wednesdayPost, setWednesdayPost] = useState(false)
    const [thursdayPost, setThursdayPost] = useState(false)
    const [fridayPost, setFridayPost] = useState(false)
    const [saturdayPost, setSaturdayPost] = useState(false)
    const [sundayPost, setSundayPost] = useState(false)
    const [show, setShow] = useState(false);
    const [text, setText] = useState('')


    const navigate = useNavigate();
    const user = fetchUser();
    const alreadySaved = !!(save?.filter((item) => item.postedBy._id === user.sub))?.length;
    const savedMonday = !!(monday?.filter((item) => item.postedBy._id === user.sub))?.length;
    const savedTuesday = !!(tuesday?.filter((item) => item.postedBy._id === user.sub))?.length;
    const savedWednesday = !!(wednesday?.filter((item) => item.postedBy._id === user.sub))?.length;
    const savedThursday = !!(thursday?.filter((item) => item.postedBy._id === user.sub))?.length;
    const savedFriday = !!(friday?.filter((item) => item.postedBy._id === user.sub))?.length;
    const savedSaturday = !!(saturday?.filter((item) => item.postedBy._id === user.sub))?.length;
    const savedSunday = !!(sunday?.filter((item) => item.postedBy._id === user.sub))?.length;
    

    const [value, setValue] = React.useState(0);
    const [showMenu, setShowMenu] = useState(false);
    // useEffect(() => {
    //     console.log('count is now', value);
    // }, [value]);
    
    const savePin = (id) => {
        if(!alreadySaved) {
            setSavingPost(true);

            client
                .patch(id)
                .setIfMissing({ save: [] })
                .insert('after', 'save[-1]', [{
                    _key: uuidv4(),
                    userId: user.sub,
                    postedBy: {
                        _type: 'postedBy',
                        _ref: user.sub
                    }
                }])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const unsavePin = (id) => {
        if(alreadySaved) {
            setSavingPost(false);

            client
                .patch(id)
                .unset([`save[-1]`])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const saveMonday = (id) => {
        if(!savedMonday) {
            setMondayPost(true);

            client
                .patch(id)
                .setIfMissing({ monday: [] })
                .insert('after', 'monday[-1]', [{
                    _key: uuidv4(),
                    userId: user.sub,
                    postedBy: {
                        _type: 'postedBy',
                        _ref: user.sub
                    }
                }])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const unsaveMonday = (id) => {
        if(savedMonday) {
            setSavingPost(false);

            client
                .patch(id)
                .unset([`monday[-1]`])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const saveTuesday = (id) => {
        if(!savedTuesday) {
            setTuesdayPost(true);

            client
                .patch(id)
                .setIfMissing({ tuesday: [] })
                .insert('after', 'tuesday[-1]', [{
                    _key: uuidv4(),
                    userId: user.sub,
                    postedBy: {
                        _type: 'postedBy',
                        _ref: user.sub
                    }
                }])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const unsaveTuesday = (id) => {
        if(savedTuesday) {
            setSavingPost(false);

            client
                .patch(id)
                .unset([`tuesday[-1]`])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const saveWednesday = (id) => {
        if(!savedWednesday) {
            setWednesdayPost(true);

            client
                .patch(id)
                .setIfMissing({ wednesday: [] })
                .insert('after', 'wednesday[-1]', [{
                    _key: uuidv4(),
                    userId: user.sub,
                    postedBy: {
                        _type: 'postedBy',
                        _ref: user.sub
                    }
                }])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const unsaveWednesday = (id) => {
        if(savedWednesday) {
            setSavingPost(false);

            client
                .patch(id)
                .unset([`wednesday[-1]`])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const saveThursday = (id) => {
        if(!savedThursday) {
            setThursdayPost(true);

            client
                .patch(id)
                .setIfMissing({ thursday: [] })
                .insert('after', 'thursday[-1]', [{
                    _key: uuidv4(),
                    userId: user.sub,
                    postedBy: {
                        _type: 'postedBy',
                        _ref: user.sub
                    }
                }])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const unsaveThursday = (id) => {
        if(savedThursday) {
            setSavingPost(false);

            client
                .patch(id)
                .unset([`thursday[-1]`])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const saveFriday = (id) => {
        if(!savedFriday) {
            setFridayPost(true);

            client
                .patch(id)
                .setIfMissing({ friday: [] })
                .insert('after', 'friday[-1]', [{
                    _key: uuidv4(),
                    userId: user.sub,
                    postedBy: {
                        _type: 'postedBy',
                        _ref: user.sub
                    }
                }])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const unsaveFriday = (id) => {
        if(savedFriday) {
            setSavingPost(false);

            client
                .patch(id)
                .unset([`friday[-1]`])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const saveSaturday = (id) => {
        if(!savedSaturday) {
            setSaturdayPost(true);

            client
                .patch(id)
                .setIfMissing({ saturday: [] })
                .insert('after', 'saturday[-1]', [{
                    _key: uuidv4(),
                    userId: user.sub,
                    postedBy: {
                        _type: 'postedBy',
                        _ref: user.sub
                    }
                }])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const unsaveSaturday = (id) => {
        if(savedSaturday) {
            setSavingPost(false);

            client
                .patch(id)
                .unset([`saturday[-1]`])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const saveSunday = (id) => {
        if(!savedSunday) {
            setSundayPost(true);

            client
                .patch(id)
                .setIfMissing({ sunday: [] })
                .insert('after', 'sunday[-1]', [{
                    _key: uuidv4(),
                    userId: user.sub,
                    postedBy: {
                        _type: 'postedBy',
                        _ref: user.sub
                    }
                }])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const unsaveSunday = (id) => {
        if(savedSunday) {
            setSavingPost(false);

            client
                .patch(id)
                .unset([`sunday[-1]`])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const deletePin = (id) => {
        client
            .delete(id)
            .then(() => {
                window.location.reload();
            })
    }

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
       
        <div className='md-2 mb-6 mr-3 bg-white hover:shadow-lg rounded-lg transition-all duration-500 ease-in-out'
            onMouseEnter={() => setPostHovered(true)}
            onMouseLeave={() => setPostHovered(false)}
        >
            <div className='relative cursor-pointer w-auto  overflow-hidden  p-3'> 
                <div
                onClick={() => navigate(`/pin-detail/${_id}`)}
                >
                    <img className='rounded-lg w-full' alt='user-post' src={urlFor(image).width(250).url()}/> 
                </div>
                <button onClick={toggleMenu}>Toggle Dropdown</button>
            {showMenu && (
                <div className="dropdown-menu">
                    <a href="#">Menu Item 1</a>
                    <a href="#">Menu Item 2</a>
                    <a href="#">Menu Item 3</a>
                    {/* Add more menu items here */}
                </div>
            )}

                {postHovered && (
                    <div className=' top-0 w-full h-full flex flex-col justify-between pt-2 pb-2  z-50 pr-1 ' style={{height: '100%'}}>
                        
                        <div className='absolute flex top-0 justify-between items-center gap-2 w-full' style={{right: '1px', paddingTop: '13px'}}>
                            
                            <div className='pl-6 pt-1'>
                                {alreadySaved ? (
                                    <button type='button' className='bg-white border-solid border border-black opacity-70 hover:opacity-100 text-dark font-bold px-2 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        unsavePin(_id)
                                        }}>
                                        {/* {save?.length}  */}
                                        <BsFillBookmarkFill/>
                                    </button>
                                ) : (
                                    <button type='button' className='bg-white border-solid border border-black opacity-70 hover:opacity-100 text-dark font-bold px-2 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            savePin(_id);
                                        }}>
                                        <BsBookmark />
                                    </button>
                                )}   
                            </div>  
                            <div className='pr-6 pt-1'>     
                                {postedBy?._id === user?.sub && (
                                    <button type='button' 
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            deletePin(_id);
                                        }}
                                        className='bg-white border-solid border border-black p-2 opacity-70 hover:opacity-100 font-bold text-dark text-base rounded-3xl hover:shadow-md outlined-none'>
                                            <AiTwotoneDelete />
                                        </button>
                                )}  
                            </div>  
                                          
                        </div>
                        
                        <Link to={`/home/user-profile/${postedBy?._id}`} className='flex gap-2 mt-2 items-center' >
                            <img className='w-8 h-8 rounded-full object-cover' src={postedBy?.image} alt='user-profile'/>
                            <p className='font-semibold capitalize'>{postedBy?.userName}</p>
                        </Link>
                        
                        <div className='flex items-center justify-between w-full'>
                            {/* <div className='flex gap-2'>
                                <a
                                    href={`${image?.asset?.url}?dl=`}
                                    download
                                    onClick={(e) => e.stopPropagation()}
                                    className='bg-white w-9 h-9 rounded-full flex items-center justify-center text-dark text-xl opacity-75 hover:opacity-100 hover:shadow-md outline-none'
                                    >
                                    <MdDownloadForOffline />
                                </a>
                            </div> */}
                            
                        </div>
                        <div  className='flex justify-between'>
                        {savedMonday ? (
                                <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-1 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    unsaveMonday(_id)
                                    setText('Monday')
                                    }}>
                                    {/* {save?.length}  */}
                                    <TbSquareRoundedLetterM/>
                                </button>
                            ) : (
                                <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-1 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        saveMonday(_id);
                                    }}>
                                    <TbCircleLetterM />
                                </button>
                            )}
                            {savedTuesday ? (
                                <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-1 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    unsaveTuesday(_id)
                                    setText('Tuesday')
                                    }}>
                                    {/* {save?.length}  */}
                                    <TbSquareRoundedLetterT/>
                                </button>
                            ) : (
                                <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-1 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        saveTuesday(_id);
                                    }}>
                                    <TbCircleLetterT />
                                </button>
                            )}
                            {savedWednesday ? (
                                <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-1 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    unsaveWednesday(_id)
                                    setText('Wednesday')
                                    }}>
                                    {/* {save?.length}  */}
                                    <TbSquareRoundedLetterW/>
                                </button>
                            ) : (
                                <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-1 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        saveWednesday(_id);
                                    }}>
                                    <TbCircleLetterW />
                                </button>
                            )}
                            {savedThursday ? (
                                <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-1 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    unsaveThursday(_id)
                                    setText('Thursday')
                                    }}>
                                    {/* {save?.length}  */}
                                    <TbSquareRoundedLetterT/>
                                </button>
                            ) : (
                                <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-1 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        saveThursday(_id);
                                    }}>
                                    <TbCircleLetterT />
                                </button>
                            )}
                            {savedFriday ? (
                                <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-1 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    unsaveFriday(_id)
                                    setText('Friday')
                                    }}>
                                    {/* {save?.length}  */}
                                    <TbSquareRoundedLetterF/>
                                </button>
                            ) : (
                                <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-1 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        saveFriday(_id);
                                    }}>
                                    <TbCircleLetterF />
                                </button>
                            )}
                            {savedSaturday ? (
                                <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-1 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    unsaveSaturday(_id)
                                    setText('Saturday')
                                    }}>
                                    {/* {save?.length}  */}
                                    <TbSquareRoundedLetterS/>
                                </button>
                            ) : (
                                <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-1 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        saveSaturday(_id);
                                    }}>
                                    <TbCircleLetterS />
                                </button>
                            )}
                            {savedSunday ? (
                                <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-1 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    unsaveSunday(_id)
                                    setText('Sunday')
                                    }}>
                                    {/* {save?.length}  */}
                                    <TbSquareRoundedLetterS/>
                                </button>
                            ) : (
                                <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-1 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        saveSunday(_id);
                                    }}>
                                    <TbCircleLetterS />
                                </button>
                            )}
                            
                            </div>
                        {/* {alreadySaved ? (
                                <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-5 py-1 text-base rounded-full hover:shadow-md outlined-none'>
                                    {save?.length} 
                                    <AiFillHeart/>
                                </button>
                            ) : (
                                <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-5 py-1 text-base rounded-full hover:shadow-md outlined-none'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        savePin(_id);
                                    }}>
                                    <AiOutlineHeart />
                                </button>
                            )} */}
                            
                    </div>
                )}
            </div>
            
                        
        </div>
    )
}

export default Pin