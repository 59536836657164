import React from "react";
import { Link } from "react-router-dom";
import NavbarFooter from "./NavbarFooter";
import MobileNavbar from "./MobileNavbar";
import { BiSolidUser } from "react-icons/bi";
import { IoIosMail } from "react-icons/io";

const Settings = ({user}) => {

    return (
        <div>
            <div className="flex bg-gray-50">
                <MobileNavbar />
            </div> 
            <h1 className="text-2xl font-sans text-center items-center justify-center pt-10">Settings</h1>
            <div className="flex grid grid-cols-2 p-10" style={{paddingBottom:"90px"}}>

                <Link to={`/home/user-profile/${user?._id}`} className="">  
                    <div  className=" shadow-xl content-center border-1 border-gray text-center bg-white items-center justify-center pt-8 rounded-xl text-xl" style={{width:"130px", height:"130px"}}>
                        <div className="content-center items-center justify-center pl-10">
                            <BiSolidUser  style={{width:"40px", height:"40px"}}/>
                        </div>
                        Profile Page
                    </div>
                </Link> 

                <Link to="/contact-us">
                    <div  className=" shadow-xl content-center border-1 border-gray text-center bg-white items-center justify-center pt-8 rounded-xl text-xl" style={{width:"130px", height:"130px"}}>
                        <div className="content-center items-center justify-center pl-10">
                            <IoIosMail  style={{width:"40px", height:"40px"}}/>
                        </div>
                        Contact Us
                    </div>
                </Link>
                <Link>
                    <div  className=" shadow-xl content-center border-1 border-gray bg-white text-center items-center justify-center pt-8 rounded-xl text-xl" style={{width:"130px", height:"130px"}}>
                        <div className="content-center items-center justify-center pl-10">
                            <BiSolidUser  style={{width:"40px", height:"40px"}}/>
                        </div>
                        Privacy Policy
                    </div>
                </Link>
            </div>
            <div className="w-screen" style={{position:"fixed", bottom:"0"}}><NavbarFooter /></div>
        </div>
    )
}

export default Settings