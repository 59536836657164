import React from "react";
import { useNavigate, useOutlet } from "react-router-dom";
import Video from '../assets/video1.mp4'
import { GoogleLogin, googleLogout } from '@react-oauth/google'
import { FcGoogle } from 'react-icons/fc'
import { useEffect, useState } from "react";
import jwt_decode from 'jwt-decode'
import {client} from '../client'


const Login = () => {
    const navigate = useNavigate()
    const [ user, setUser ] = useState({})

    function handleCallbackResponse(response) {
        console.log("Encoded JWT ID token:" + response.credential);
        var userObject = jwt_decode(response.credential);
        console.log(userObject)
        setUser(userObject)

        localStorage.setItem('user', JSON.stringify(userObject))
        const {name, picture, sub} = userObject

        const doc = {
            _id: sub,
            _type: 'user',
            userName: name,
            image: picture
        }

        client.createIfNotExists(doc)
        .then(() => {
            navigate('/home', { replace: true })
        })
    }

    useEffect(() => {
        /* global google */
        google.accounts.id.initialize({
            client_id: "113701729174-66m5vnoumnht3qdur8edq5vau0mkqe03.apps.googleusercontent.com",
            callback: handleCallbackResponse
        })

        google.accounts.id.renderButton(
            document.getElementById("signInDiv"),
            { theme: "outline", size: "large"}
        )

    }, []);
    
    if(user) {
            navigate('/home')
        }

    return (

        
        <div className="flex justify-start items-center flex-col h-screen">
            <div className="relative w-full h-full">
                <video 
                src={Video}
                type="video/mp4"
                loop
                controls={false}
                muted
                autoPlay
                className="w-full h-full object-cover"
                />
                <div className="absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0 bg-blackOverlay">
                    <div className="p-5">
                        {/* <h7>SuperCloset</h7> */}
                    </div>
                    <div id="signInDiv" className="shadow-2xl">
                     
                        {/* <GoogleLogin                             
                                    // type="button"
                            className="bg-mainColor"
                            onSuccess={(credentialResponse) => 
                            createOrGetUser(credentialResponse)
                            }
                            onError={() => console.log('Error')}                                                      
                        />; */}
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login