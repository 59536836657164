import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <div className="hidden md:block">
        <div className="flex relative fixed h-32 w-100 bg-slate-700 flex-row justify-center items-center text-center gap-10 text-white"  >
            {/* <div className=" flex flex-col pl-3 absolute inset-y-0 left-0 w-30 justify-center items-center text-center">
                <label className="text-xl ">Supercloset</label>
                <p className=" text-sm ">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit 
                </p>
            </div> */}
            <div className="flex flex-col justify-center items-center text-center">
                <label className="text-xl pb-2">Important Links</label>
                <Link className="text-sm">Privacy Policy</Link>
                <Link className="text-sm" to='/contact-us'>Contact Us</Link>
            </div>
            <div className="flex flex-col justify-center items-center text-center">
                <label className="text-xl pb-2">Socials</label>
                <Link className="text-sm">Instagram</Link>
                <Link className="text-sm">Facebook</Link>
            </div>
        </div>
        </div>
    )
}

export default Footer