import React, {useEffect, useState} from "react"
import { AiOutlineCloudUpload } from "react-icons/ai"
import { MdDelete } from "react-icons/md"
import { useNavigate } from "react-router-dom"

import { client } from "../client"
import Spinner from './Spinner'
import { fetchUser } from "../utils/fetchUser"
import { userQuery } from "../utils/data"
import Navbar from "./Navbar"
import MobileNavbar from "./MobileNavbar"
import Footer from "./Footer"
import NavbarFooter from "./NavbarFooter"

const CreateVCPin = ({user, searchTerm, setSearchTerm}) => {

//     const userInfo = fetchUser();
//     const [user, setUser] = useState()
  
//     useEffect(() => {
//       const query = userQuery(userInfo?.sub)
  
//       client.fetch(query)
//           .then((data) => {
//               setUser(data[0])
//           })
//   }, []);




    const [loading, setLoading] = useState(false)
    const [fields, setFields] = useState(false)
    const [imageAsset, setImageAsset] = useState(null)
    const [wrongImageType , setWrongImageType] = useState(false)

    const navigate = useNavigate()

    const uploadImage = (e) => {
        const {type, name} = e.target.files[0];

        if( type === 'image/jpeg' || type === 'image/svg' || type === 'image/png' || type === 'image/gif' || type === 'image/tiff') {
            setWrongImageType(false);
            setLoading(true)

            client.assets
                .upload('image', e.target.files[0], { contentType: type, filename: name})
                .then((document) => {
                    setImageAsset(document);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log('Image upload error ', error);
                })
        } else {
            setWrongImageType(true);
        }
    }

    const savePin = () => {
        if(imageAsset?._id) {
            const doc = {
                _type: 'vcpin',
                image: {
                    _type: 'image',
                    asset: {
                        _type: 'reference',
                        _ref:imageAsset?._id
                    }
                },
                userId: user._id,
                postedBy: {
                    _type: 'postedBy',
                    _ref: user._id,
                }
            }

            client.create(doc)
                .then(() => {
                    navigate(`/home/virtual-closet/${user?._id}`)
                })
        } else {
            setFields(true)

            setTimeout(() => setFields(false), 2000)
        }
    }

    return (
        <div>
            <div className="bg-gray-50 hidden md:block">
                <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} user={user && user} />     
            </div>
            {/* <div className="flex bg-gray-50">
            <MobileNavbar/>
        </div> */}
        <div className="flex flex-col justify-center items-center mt-5 mb-5 px-4 lg:h-4/5">
            
            {fields && (
                <p className="text-red-500 mb-5 text-xl transition-all duration-150 ease-in">Please fill in all the fields.</p>
            )}
            <div className="flex flex-col justify-center items-center bg-white lg:p-5 p-3 lg:w-4/5 w-full rounded-xl">
                <div className="bg-secondaryColor p-3 flex flex-0.7 w-full ">
                    
                    <div className="flex justify-center items-center flex-col border-2 border-dotted border-gray-300 p-3 w-full h-420 flex-col">
                        {loading && <Spinner />}
                        {wrongImageType && <p>Wrong image type</p>}
                        {!imageAsset ? (
                            <label>
                                <div className="flex flex-col items-center justify-center h-full">
                                    <div className="flex flex-col justify-center items-center">
                                        <p className="font-bold text-2xl "> <AiOutlineCloudUpload /> </p>
                                        <p className="text-lg">Click to upload</p>
                                    </div>
                                    <p className="mt-32 text-gray-400">Use high-quality JPG, SVG, PNG, GIF less than 20 MB</p>
                                </div>
                                <input 
                                    type="file"
                                    name="upload-image"
                                    onChange={uploadImage}
                                    className="w-0 h-0"
                                    />
                            </label>
                        ): (
                           <div className="relative h-full">
                            <img src={imageAsset?.url} alt="uploaded-pic" className="h-full w-full"/>
                            <button type="button" className="absolute bottom-3 right-3 p-3 rounded-full bg-white text-xl cursor-pointer outline-none hover:shadow-md transition-all duration-500 ease-in-out" onClick={() => setImageAsset(null)}> <MdDelete /> </button>
                           </div>
                        )}
                        
                    </div>

                    

                    

                   
                                         
                </div>
                
                <button type="button" onClick={savePin} className=" flex justify-center mt-6 items-center bg-red-500 text-white font-bold p-2 rounded -full w-28 outline-none">
                            Post 
                </button>
                
                
            </div>
        </div>
        {/* <Footer /> */}
        <div className="w-screen" style={{position:"fixed", bottom:"0"}}><NavbarFooter /></div>
        </div>
    )
}

export default CreateVCPin