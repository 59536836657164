import { useState } from 'react'
import a from "../assets/a.jpg"
import b from "../assets/b.jpg"
import c from "../assets/c.jpg"
import d from "../assets/d.jpg"
import e from "../assets/e.jpg"
import f from "../assets/f.jpg"
import g from "../assets/g.jpg"
import h from "../assets/h.jpg"


import Navbar from './Navbar'
import { Link } from 'react-router-dom'
import MobileNavbar from './MobileNavbar'
import Footer from './Footer'
import NavbarFooter from './NavbarFooter'
import ReactCardFlip from "react-card-flip";
import M from 'materialize-css'



const Looks = ({user}) => {

  const [looks, setLooks] = useState()

  
  const DATA = [
    {
      id: 2,
      image: b,
      tags: [
        {
          id: 'tag1',
          title: 'Smart Casual',
          slug: 'smart_casual',
        },
        {
          id: 'tag7',
          title: 'Dress-Shirt',
          slug: 'dress_shirt',
        },
        {
          id: 'tag6',
          title: 'Boots',
          slug: 'boots',
        },
        {
          id: 'tag8',
          title: 'Kacki',
          slug: 'kacki',
        },
        {
          id: 'tag9',
          title: 'Sweater',
          slug: 'sweater',
        },
      ],
      desc: '- Light Blue Oxford Shirt',
      desc1: 'Gray V-neck Sweater',
      desc2: '- Kacki Chinos',
      desc3: '- Brown Suede Boots',
      desc4: '- Brown Leather Belt',
      link: `https://www.youtube.com`

    },
    {
      id: 3,
      image:c,
      tags: [
        {
          id: 'tag1',
          title: 'Smart Casual',
          slug: 'smart_casual',
        },
        {
          id: 'tag9',
          title: 'Blazer',
          slug: 'Blazer',
        },
        {
          id: 'tag5',
          title: 'Jeans',
          slug: 'jeans',
        },
        {
          id: 'tag11',
          title: 'Loafers',
          slug: 'loafers',
        },
        {
          id: 'tag7',
          title: 'Dress Shirt',
          slug: 'dress_shirt'
        },
      ],
      desc: '- White Button-Down Shirt',
      desc1: 'Navy Blue Blazer',
      desc2: '- Dark Wash Jeans',
      desc3: '- Brown Leather Loafers',
      desc4: '- Brown Leather Belt'

    },
    {
      id: 4,
      image: d,
      tags: [
        {
          id: 'tag7',
          title: 'Dress Shirt',
          slug: 'dress_shirt',
        },
        {
          id: 'tag11',
          title: 'Shoes',
          slug: 'shoes',
        },
        {
          id: 'tag5',
          title: 'Jeans',
          slug: 'jeans',
        },
        {
          id: 'tag1',
          title: 'Smart Casual',
          slug: 'smart_casual',
        },
      ],
      desc: '- White Dress Shirt',
      desc1: 'Dark Wash Jeans',
      desc2: '- Brown Leather Shoes',
      desc3: '- Brown Leather Belt'
    },
    {
      id: 5,
      image: e,
      tags: [
        {
          id: 'tag7',
          title: 'Shirt',
          slug: 'shirt',
        },
        {
          id: 'tag11',
          title: 'Shoes',
          slug: 'shoes',
        },
        {
          id: 'tag31',
          title: 'Dress Pants',
          slug: 'dress_pants',
        },
        {
          id: 'tag30',
          title: 'Minimalism',
          slug: 'minimalism',
        },
      ],
      desc: '- Black Crew-Neck T-shirt',
      desc1: 'Charcoal Greay Slim Trousers',
      desc2: '- White Low Top Sneakers',
      desc3: '- Black Leather Belt'
    },
    {
      id: 6,
      image: f,
      tags: [
        {
          id: 'tag7',
          title: 'Dress Shirt',
          slug: 'dress_shirt',
        },
        {
          id: 'tag11',
          title: 'Dress Shoes',
          slug: 'dress_shoes',
        },
        {
          id: 'tag1',
          title: 'Smart Casual',
          slug: 'smart_casual',
        },
        {
          id: 'tag32',
          title: 'Cardigan',
          slug: 'cardigan'
        },
        {
          id: 'tag13',
          title: 'Dress Pants',
          slug: 'dress_pants'
        }
      ],
      mainLink: `/pin-detail/j00OuwKyPCKi4fkLzdxztC`,
      desc: '- Light Grey Dress Shirt',
      desc1: 'Charcoal Grey Cardigan',
      desc2: '- Navy Blue Trousers',
      desc3: '- Brown Leather Brogues',
      desc4: '- Burgundy Tie & Brown Leather Belt'
    },
    {
      id: 7,
      image: g,
      tags: [
        {
          id: 'tag34',
          title: 'Polo-shirt',
          slug: 'polo_shirt',
        },
        {
          id: 'tag35',
          title: 'Sneakers',
          slug: 'sneakers',
        },
        {
          id: 'tag8',
          title: 'Khaki',
          slug: 'khaki',
        },
        {
          id: 'tag33',
          title: 'Jacket',
          slug: 'jacket',
        },
        {
          id: 'tag1',
          title: 'Smart Casual',
          slug: 'smart_casual',
        },
      ],
      desc: '- Navy Blue Bomber Jacket',
      desc1: 'Navy Blue Polo Shirt',
      desc2: '- Beige Chinos',
      desc3: '- White Sneakers',
      desc4: '- Brown Leather Belt'
    },
    {
      id: 8,
      image: h,
      tags: [
        {
          id: 'tag7',
          title: 'Dress Shirt',
          slug: 'dress_shirt',
        },
        {
          id: 'tag11',
          title: 'Dress Shoes',
          slug: 'dress_shoes',
        },
        {
          id: 'tag13',
          title: 'Dress Pants',
          slug: 'dress_pants'
        },
        {
          id: 'tag1',
          title: 'Smart Casual',
          slug: 'smart_casual',
        },
        {
          id: 'tag9',
          title: 'Blazer',
          slug: 'blazer'
        }
      ],
      desc: '- Navy Blue Blazer',
      desc1: 'Light Pink Dress Shirt',
      desc2: '- Dark Gray Trousers Slim Fit',
      desc3: '- Brown Leather Shoes',
    },
    
  ]
  

  const [filterTags, setFilterTags] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [show, setShow] = useState(true)
  const [flip, setFlip] = useState(false);

  const filteredDATA = DATA.filter((node) =>
    filterTags.length > 0
      ? filterTags.every((filterTag) =>
          node.tags.map((tag) => tag.slug).includes(filterTag)
        )
      : DATA
  )

  const filterHandler = (event) => {
    if (event.target.checked) {
      setFilterTags([...filterTags, event.target.value])
    } else {
      setFilterTags(
        filterTags.filter((filterTag) => filterTag !== event.target.value)
      )
    }
  }

  return (
    <div>
        <div className="bg-gray-50 hidden md:block">
                <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} user={user && user} />
        </div>
        <div className="flex bg-gray-50">
            <MobileNavbar />
        </div>
        
        
        {/* <button className='lg:hidden md:hidden' onClick={() => setShow(!show)}>Filter</button>
        
        { show? 
        <div className='flex border-r-2 p-4 pr-10 bg-slate-600 flex-col justify-between h-screen sticky top-0 overflow-y-scroll min-w-210 hide-scrollbar overflow:hidden lg:hidden md:hidden'>
            <div className='flex flex-col text-gray-400 ' style={{overscrollBehavior:"contain"}}>
                <span >Styles</span>
                <label htmlFor="smart_casual" >
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="smart_casual"
                    id="smart_casual"
                />
                <span >Smart Casual</span>
                </label>
                <label htmlFor="casual" style={{borderBottom:"1px solid grey"}}>
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="casual"
                    id="casual"
                    style={{ marginBottom:"20px"}}
                />
                <span style={{ marginBottom:"20px"}}>Casual</span>
                </label>
                
                <br/>
                <span >Shirt</span>
                <label htmlFor="button_up">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="button_up"
                    id="button_up"
                />
                <span >Button Up</span>
                </label>
                <label htmlFor="shirt">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="shirt"
                    id="shirt"
                />
                <span >Shirt</span>
                </label>
                <label htmlFor="button_down">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="button_down"
                    id="button_down"
                />
                <span >Button Down</span>
                </label>           
                <label htmlFor="sweater" style={{borderBottom:"1px solid grey"}}>
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="sweater"
                    id="sweater"
                    style={{ marginBottom:"20px"}}
                />
                <span style={{ marginBottom:"20px"}}>Sweater</span>
                </label>
                
                <br/>
                <span >Jackets</span>
                <label htmlFor="blazer" >
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="blazer"
                    id="blazer"
                />
                <span >Blazer</span>
                </label>
                <label htmlFor="windbreaker" >
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="windbreaker"
                    id="windbreaker"
                />
                <span >Windbreaker</span>
                </label>
                <label htmlFor="coat" >
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="coat"
                    id="coat"
                />
                <span >Coat</span>
                </label>
                <label htmlFor="hoodie" >
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="hooide"
                    id="hoodie"
                />
                <span >Hoodie</span>
                </label>
                <label htmlFor="jacket" style={{borderBottom:"1px solid grey"}}>
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="jacket"
                    id="jacket"
                    style={{ marginBottom:"20px"}}
                />
                <span style={{ marginBottom:"20px"}}>Jacket</span>
                </label>
                
                <br/>
                <span >Pants</span>
                <label htmlFor="jeans">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="jeans"
                    id="jeans"
                />
                <span >Jeans</span>
                </label>
                <label htmlFor="dress_pants">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="dress_pants"
                    id="dress_pants"
                />
                <span >Dress Pants</span>
                </label>
                <label htmlFor="sweats">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="sweats"
                    id="sweats"
                />
                <span >Sweats</span>
                </label>
                <label htmlFor="shorts">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="shorts"
                    id="shorts"
                />
                <span >Shorts</span>
                </label>
                <label htmlFor="cargo_pants">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="cargo_pants"
                    id="cargo_pants"
                />
                <span >Cargo Pants</span>
                </label>
                <label htmlFor="kacki" style={{borderBottom:"1px solid grey"}}>
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="kacki"
                    id="kacki"
                    style={{ marginBottom:"20px"}}
                />
                <span style={{ marginBottom:"20px"}}>Kacki</span>
                </label>

                <br/>
                <span >Shoes</span>
                <label htmlFor="shoes">
                <input
                    
                    type="checkbox"
                    onChange={filterHandler}
                    value="shoes"
                    id="shoes"
                />
                <span >Shoes</span>
                </label>
                <label htmlFor="dress_shoes">
                <input
                    
                    type="checkbox"
                    onChange={filterHandler}
                    value="dress_shoes"
                    id="dress_shoes"
                />
                <span >Dress Shoes</span>
                </label>
                <label htmlFor="boots" style={{borderBottom:"1px solid grey"}}>
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="boots"
                    id="boots"
                    style={{ marginBottom:"20px"}}
                />
                <span style={{ marginBottom:"20px"}}>Boots</span>
            
                </label>                
            </div>     
        </div>:null}
         */}

        <div className="min-h-screen flex">
        <div className='flex border-r-2 p-4 pr-10 bg-slate-600 flex-col justify-between h-screen sticky top-0 overflow-y-scroll min-w-210 hide-scrollbar overflow:hidden hidden md:block'>
            <div className='flex flex-col text-gray-400 ' style={{overscrollBehavior:"contain"}}>
                <span >Styles</span>
                <label htmlFor="smart_casual" >
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="smart_casual"
                    id="smart_casual"
                />
                <span >Smart Casual</span>
                </label>
                <label htmlFor="minimalism" >
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="minimalism"
                    id="minimalism"
                />
                <span >Minimalist</span>
                </label>
                <label htmlFor="casual" style={{borderBottom:"1px solid grey"}}>
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="casual"
                    id="casual"
                    style={{ marginBottom:"20px"}}
                />
                <span style={{ marginBottom:"20px"}}>Casual</span>
                </label>
                
                <br/>
                <span >Shirt</span>
                <label htmlFor="dress_shirt">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="dress_shirt"
                    id="dress_shirt"
                />
                <span >Dress Shirt</span>
                </label>
                <label htmlFor="shirt">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="shirt"
                    id="shirt"
                />
                <span >Shirt</span>
                </label>       
                <label htmlFor="polo_shirt">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="polo_shirt"
                    id="polo_shirt"
                />
                <span >Polo Shirts</span>
                </label>    
                <label htmlFor="sweater" style={{borderBottom:"1px solid grey"}}>
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="sweater"
                    id="sweater"
                    style={{ marginBottom:"20px"}}
                />
                <span style={{ marginBottom:"20px"}}>Sweater</span>
                </label>
                
                <br/>
                <span >Jackets</span>
                <label htmlFor="blazer" >
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="blazer"
                    id="blazer"
                />
                <span >Blazer</span>
                </label>
                <label htmlFor="cardigan" >
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="cardigan"
                    id="cardigan"
                />
                <span >Cardigan</span>
                </label>
                <label htmlFor="jacket" style={{borderBottom:"1px solid grey"}}>
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="jacket"
                    id="jacket"
                    style={{ marginBottom:"20px"}}
                />
                <span style={{ marginBottom:"20px"}}>Jacket</span>
                </label>
                
                <br/>
                <span >Pants</span>
                <label htmlFor="jeans">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="jeans"
                    id="jeans"
                />
                <span >Jeans</span>
                </label>
                <label htmlFor="dress_pants">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="dress_pants"
                    id="dress_pants"
                />
                <span >Dress Pants</span>
                </label>
                <label htmlFor="kacki" style={{borderBottom:"1px solid grey"}}>
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="kacki"
                    id="kacki"
                    style={{ marginBottom:"20px"}}
                />
                <span style={{ marginBottom:"20px"}}>Kacki/Chinos</span>
                </label>

                <br/>
                <span >Shoes</span>
                <label htmlFor="loafers">
                <input
                    
                    type="checkbox"
                    onChange={filterHandler}
                    value="loafers"
                    id="loafers"
                />
                <span >Loafers</span>
                </label>
                <label htmlFor="dress_shoes">
                <input
                    
                    type="checkbox"
                    onChange={filterHandler}
                    value="dress_shoes"
                    id="dress_shoes"
                />
                <span >Dress Shoes</span>
                </label>
                <label htmlFor="sneakers">
                <input
                    
                    type="checkbox"
                    onChange={filterHandler}
                    value="sneakers"
                    id="sneakers"
                />
                <span >Sneakers</span>
                </label>
                <label htmlFor="boots" style={{borderBottom:"1px solid grey"}}>
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="boots"
                    id="boots"
                    style={{ marginBottom:"20px"}}
                />
                <span style={{ marginBottom:"20px"}}>Boots</span>
            
                </label>                
            </div>
        </div>
        
        <div className='flex  border-r-2 px-1 bg-slate-600 flex-col justify-between h-screen sticky top-0 overflow-y-scroll hide-scrollbar overflow:hidden lg:hidden md:hidden w-full' >
            <div className='flex flex-col text-white text-xs' style={{overscrollBehavior:"contain", paddingBottom:"80px"}}>
                <span >Styles</span>
                <label htmlFor="smart_casual" >
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="smart_casual"
                    id="smart_casual"
                />
                <span >Smart Casual</span>
                </label>
                <label htmlFor="minimalist" >
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="minimalist"
                    id="minimalist"
                />
                <span >Minimalist</span>
                </label>
                <label htmlFor="casual" style={{borderBottom:"1px solid grey"}}>
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="casual"
                    id="casual"
                    style={{ marginBottom:"20px"}}
                />
                <span style={{ marginBottom:"20px"}}>Casual</span>
                </label>
                
                <br/>
                <span >Shirt</span>
                <label htmlFor="dress_shirt">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="dress_shirt"
                    id="dress_shirt"
                />
                <span >Dress Shirt</span>
                </label>
                <label htmlFor="shirt">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="shirt"
                    id="shirt"
                />
                <span >Shirt</span>
                </label>
                <label htmlFor="polo_shirt">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="polo_shirt"
                    id="polo_shirt"
                />
                <span >Polo Shirts</span>
                </label>           
                <label htmlFor="sweater" style={{borderBottom:"1px solid grey"}}>
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="sweater"
                    id="sweater"
                    style={{ marginBottom:"20px"}}
                />
                <span style={{ marginBottom:"20px"}}>Sweater</span>
                </label>
                
                <br/>
                <span >Jackets</span>
                <label htmlFor="blazer" >
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="blazer"
                    id="blazer"
                />
                <span >Blazer</span>
                </label>
                <label htmlFor="cardigan" >
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="cardigan"
                    id="cardigan"
                />
                <span >Cardigan</span>
                </label>
                <label htmlFor="jacket" style={{borderBottom:"1px solid grey"}}>
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="jacket"
                    id="jacket"
                    style={{ marginBottom:"20px"}}
                />
                <span style={{ marginBottom:"20px"}}>Jacket</span>
                </label>
                
                <br/>
                <span >Pants</span>
                <label htmlFor="jeans">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="jeans"
                    id="jeans"
                />
                <span >Jeans</span>
                </label>
                <label htmlFor="dress_pants">
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="dress_pants"
                    id="dress_pants"
                />
                <span >Dress Pants</span>
                </label>
                <label htmlFor="kacki" style={{borderBottom:"1px solid grey"}}>
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="kacki"
                    id="kacki"
                    // style={{ marginBottom:"20px"}}
                />
                <span >Kacki/Chinos</span>
                </label>

                <br/>
                <span >Shoes</span>
                <label htmlFor="loafers">
                <input
                    
                    type="checkbox"
                    onChange={filterHandler}
                    value="loafers"
                    id="loafers"
                />
                <span >Loafers</span>
                </label>
                <label htmlFor="dress_shoes">
                <input
                    
                    type="checkbox"
                    onChange={filterHandler}
                    value="dress_shoes"
                    id="dress_shoes"
                />
                <span >Dress Shoes</span>
                </label>
                <label htmlFor="sneakers">
                <input
                    
                    type="checkbox"
                    onChange={filterHandler}
                    value="sneakers"
                    id="sneakers"
                />
                <span >Sneakers</span>
                </label>
                <label htmlFor="boots" style={{borderBottom:"1px solid grey"}}>
                <input
                    type="checkbox"
                    onChange={filterHandler}
                    value="boots"
                    id="boots"
                    style={{ marginBottom:"20px"}}
                />
                <span style={{ marginBottom:"20px"}}>Boots</span>
            
                </label>                
            </div>
        </div>

        <div className='flex-2 ' >

        
          <div className='hidden md:block'>
            <div className='grid grid-cols-3 gap-10 justify-center items-center mt-6' style={{overscrollBehavior:"contain", paddingLeft:"60px"}} >
            {filteredDATA.map((node) => (
                <div className="md-2 mb-6 mr-3 pb-6 bg-white hover:shadow-lg rounded-lg transition-all duration-500 ease-in-out" style={{marginBottom:"30px"}}>
                  <div className="" style={{padding:"10px"}} >
                    <Link to={node.mainLink}>
                      <img className="p-3" src={node.image}/>
                    </Link>
                  </div>
                  <div className="pl-3">
                    <p className='font-bold'>This look contains:</p>
                    <br/>
                    <p>{node.desc}</p>
                    <p>- <Link to={node.link} style={{textDecoration:"underline"}}>{node.desc1}</Link></p>
                    <a href={node.link} target="_blank" rel="noreferrer"><p>{node.desc2}</p></a>
                    <a href={node.link} target="_blank" rel="noreferrer"><p>{node.desc3}</p></a>
                    <a href={node.link} target="_blank" rel="noreferrer"><p>{node.desc4}</p></a>

                  </div>
                </div>
            ))}
            </div >
          </div>
          
        


          <div className='lg:hidden xl:hidden 2xl:hidden' style={{ marginBottom:"100px"}}>
            <div className='grid grid-cols-1 gap-2 items-center mt-6   ' style={{overscrollBehavior:"contain", paddingLeft: '10px', paddingRight: '10px'}} >
            {filteredDATA.map((node) => (
                <div className="md-2 mb-6 pb-6 bg-white hover:shadow-lg rounded-lg transition-all duration-500 ease-in-out" style={{marginBottom:"30px"}}>
                <div className=""  >
                  <img className="p-3" src={node.image}/>
                </div>
                <div className="pl-3">
                  <p className='font-bold'>This look contains:</p>
                  <br/>
                  <p>{node.desc}</p>
                  <p>- <Link to={node.link} style={{textDecoration:"underline"}}>{node.desc1}</Link></p>
                  <p>{node.desc2}</p>
                  <p>{node.desc3}</p>
                </div>
              </div>
            ))}
            </div >
          </div>  
        </div>
        
        </div>
        <div>
          <Footer />
        </div>
        <div className="w-screen relative" style={{position:"fixed", bottom:"0", zIndex:"1"}}><NavbarFooter /></div>
    </div>
  )
}

export default Looks


{/* <li className='blog-post' key={node.id} style={{display:"inline-block", marginLeft:"20px", padding:"10px 10px", margin:"10px", borderRadius:"10px"}}>
                <img src={node.image} />
            </li> */}

           // <p>- <Link to={node.link} style={{textDecoration:"underline"}}>{node.desc1}</Link></p>
