import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from 'react-router-dom'
import Home from "./container/Home";
import Login from "./component/Login";
import Feed from "./component/Feed";
import PinDetail from "./component/PinDetail";
import CreatePin from "./component/CreatePin";
import Search from "./component/Search";
import UserProfile from "./component/UserProfile";
import Pins from "./container/Pins";
import { fetchUser } from "./utils/fetchUser";
import { userQuery } from "./utils/data";
import { client } from "./client";
import VirtualCloset from "./component/VirtualCloset";
import CreateVCPin from "./component/CreateVCPin";
import Looks from "./component/Looks";
import WeeklyPlan from "./component/WeeklyPlan";
import ContactForm from "./component/ContactForm";
import Sidebar from "./component/Sidebar";
import Settings from "./component/Settings";
import VCPin from "./component/VCPin";

const App = () => {
  const navigate = useNavigate()
  const userInfo = fetchUser();
  const [user, setUser] = useState()
  const [searchTerm, setSearchTerm] = useState('');


  useEffect(() => {
    const query = userQuery(userInfo?.sub)

    client.fetch(query)
        .then((data) => {
            setUser(data[0])
        })
}, []);

  return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/home" element={<Feed />} />
        <Route path="/pin-detail/:pinId" element={<PinDetail user={user && user} />} />
        <Route path="/create-pin" element={<CreatePin user={user && user} />} />
        <Route path="/search" element={<Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} user={user && user}/>} />   
        <Route path="/home/user-profile/:userId" element={<UserProfile user={user && user}/>} />
        <Route path="/home" element={<Pins user={user && user} />} /> 
        <Route path="/vcpin" element={<VCPin user={user && user} />} /> 

        <Route path="/sidebar" element={<Sidebar  />} />

        <Route path="/home/virtual-closet/:userId" element={<VirtualCloset user={user && user}/>} />
        <Route path="/create-vcpin" element={<CreateVCPin user={user && user} />} />
        <Route path="/looks" element={<Looks user={user && user} />} />

        <Route path="/home/weekly-planner/:userId" element={<WeeklyPlan user={user && user}/>} />

        <Route path="/contact-us" element={<ContactForm user={user && user} />}/>
        <Route path="/settings" element={<Settings user={user && user}/>}/> 
    
      </Routes>
      )
}

export default App;
