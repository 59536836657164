import React, {useState} from 'react'
import {Routes, Route} from 'react-router-dom'
import Navbar from "../component/Navbar"
import Feed from '../component/Feed'
import PinDetail from "../component/PinDetail"
import CreatePin from "../component/CreatePin"
import Search from "../component/Search"
import CreateVCPin from '../component/CreateVCPin'
import VirtualCloset from '../component/VirtualCloset'
import WeeklyPlan from '../component/WeeklyPlan'
import Sidebar from '../component/Sidebar'
import NavbarFooter from '../component/NavbarFooter'

const Pins = ({user}) => {
    const [searchTerm, setSearchTerm] = useState('');
    
    return (
        <div className="px-2 md:px-5">
            <div className="bg-gray-50">
                <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} user={user && user} />
                <NavbarFooter user={user && user} />
            </div>
            <div className="h-full">
                <Routes>
                    <Route path="/" element={<Feed />} />
                    <Route path="/pin-detail/:pinId" element={<PinDetail user={user && user} />} />
                    <Route path="/create-pin" element={<CreatePin user={user && user} />} />
                    <Route path="/search" element={<Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />} />
                    <Route path="/create-vcpin" element={<Sidebar user={user && user} />} />
                    
                    <Route path="/create-vcpin" element={<CreateVCPin user={user && user} />} />
                    <Route path="/home/virtual-closet/:userId" element={<VirtualCloset user={user && user}/>} />

                    <Route path="/home/weekly-planner/:userId" element={<WeeklyPlan />} />
                    <Route path="/footer2" element={<NavbarFooter user={user && user}/>} />
                </Routes>
            </div>
        </div>
    )
}

export default Pins