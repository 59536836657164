import React, {useState, useEffect} from "react"
import { MdDownloadForOffline } from "react-icons/md"
import { Link, useNavigate, useParams } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"

import { client, urlFor } from "../client"
import MasonryLayout from "./MasonryLayout"
import {pinDetailMorePinQuery, pinDetailQuery} from '../utils/data'
import Spinner from "./Spinner"
import Navbar from "./Navbar"
import { IoMdAdd, IoMdSearch } from "react-icons/io"
import Sidebar from "./Sidebar"
import { TbCircleLetterF, TbCircleLetterM, TbCircleLetterS, TbCircleLetterT, TbCircleLetterW, TbSquareRoundedLetterF, TbSquareRoundedLetterM, TbSquareRoundedLetterS, TbSquareRoundedLetterT, TbSquareRoundedLetterW } from "react-icons/tb"
import MobileNavbar from "./MobileNavbar"
import Footer from "./Footer"
import NavbarFooter from "./NavbarFooter"

const PinDetail = ({user, searchTerm, setSearchTerm}) => {

    const [pins, setPins] = useState(null)
    const [pinDetail, setPinDetail] = useState(null)
    const [comment, setComment] = useState('')
    const [addingComment, setAddingComment] = useState(false)
    const [savingPost, setSavingPost] = useState()
    const [mondayPost, setMondayPost] = useState(false)
    const [tuesdayPost, setTuesdayPost] = useState(false)
    const [wednesdayPost, setWednesdayPost] = useState(false)
    const [thursdayPost, setThursdayPost] = useState(false)
    const [fridayPost, setFridayPost] = useState(false)
    const [saturdayPost, setSaturdayPost] = useState(false)
    const [sundayPost, setSundayPost] = useState(false)
    const [text, setText] = useState('')

    const {pinId} = useParams()

    const navigate = useNavigate()

    // const savedMonday = !!(monday?.filter((item) => item.postedBy._id === user.sub))?.length;
    // const savedTuesday = !!(tuesday?.filter((item) => item.postedBy._id === user.sub))?.length;
    // const savedWednesday = !!(wednesday?.filter((item) => item.postedBy._id === user.sub))?.length;
    // const savedThursday = !!(thursday?.filter((item) => item.postedBy._id === user.sub))?.length;
    // const savedFriday = !!(friday?.filter((item) => item.postedBy._id === user.sub))?.length;
    // const savedSaturday = !!(saturday?.filter((item) => item.postedBy._id === user.sub))?.length;
    // const savedSunday = !!(sunday?.filter((item) => item.postedBy._id === user.sub))?.length;

    


    const fetchPinDetails = () => {
        let query = pinDetailQuery(pinId)

        if(query) {
            client.fetch(query)
                .then((data) => {
                    setPinDetail(data[0])

                    if(data[0]) {
                        query = pinDetailMorePinQuery(data[0])

                        client.fetch(query)
                            .then((res) => setPins(res))
                    }
                })
        }
    }

    useEffect(() => {
        fetchPinDetails();
    }, [pinId])

    const addComment = () => {
        if(comment) {
            setAddingComment(true)

            client
                .patch(pinId)
                .setIfMissing({comments: []})
                .insert('after', 'comments[-1]', [{
                    comment, 
                    _key: uuidv4(),
                    postedBy: {
                        _type: 'postedBy',
                        _ref: user._id
                    }
                }])
                .commit()
                .then(() => {
                    fetchPinDetails();
                    setComment('');
                    setAddingComment(false)
                })
        }
    }

    if(!pinDetail) return <Spinner message="Loading pin details." />

    return (
        <>
        <div className="bg-gray-50 hidden md:block">
                <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} user={user && user} />
        </div>
        <div className="flex bg-gray-50">
            <MobileNavbar />
        </div>
        <div className="min-h-screen flex">
        {/* <div className="flex hidden md:block">
                <Sidebar />
            </div> */}
            <div className="flex-1">
        <div className="flex gap-2 md:gap-5 w-full mt-5 pb-7 pl-6 pr-6">
            <div className="flex justify-start items-center w-full px-2 rounded-md bg-white border-none outline-none focus-within:shadow-sm">
                <IoMdSearch fontSize={21} className="ml-1"/>
                <input 
                    type="text"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search"
                    value={searchTerm}
                    onFocus={() => navigate('/search')}
                    className="p-2 w-full bg-white outline-none"
                />
            </div>
            <div className="flex gap-3">
                <Link to='/create-pin' className="bg-black text-white rounded-lg w-12 h-12 md:w-14 md:h-12 flex justify-center items-center">
                    <IoMdAdd />
                </Link>
            </div>
        </div>
        <div className="w-full p-5 flex xl:min-w-620">
        <div className="flex xl-flex-row flex-col m-auto bg-white pt-6 rounded-xl" style={{maxWidth: '500px', right: '30px'}}>

            <div className="flex justify-center items-center md:items-start flex-initial">
                <img src={pinDetail?.image && urlFor(pinDetail.image).url()} className="px-6 rounded-t-xl rounded-b-lg" alt="user-post"/>
            </div>
                {/* <div className="flex items-center justify-between">
                    <div className="flex gap-2 items-center">
                        <a href={`${pinDetail.image.asset.url}?dl=`}></a>
                    </div>

                </div> */}
            <div className="px-6">
                <h1 className="text-4xl font-bold break-words mt-3">{pinDetail.title}</h1>
                <p className="mt-3">{pinDetail.about}</p>
            </div>
            <Link to={`/home/user-profile/${pinDetail.postedBy?._id}`} className='flex gap-2 mt-5 items-center bg-white rounded-lg px-6 pb-6' >
                <img className='w-8 h-8 rounded-full object-cover' src={pinDetail.postedBy?.image} alt='user-profile'/>
                <p className='font-semibold capitalize'>{pinDetail.postedBy?.userName}</p>
            </Link>

        </div>

            <div className="bg-white px-6 rounded-xl" style={{left: '30px'}}>
                <h2 className="mt-5  text-2xl">Comments</h2>
                <div className="max-h-370 overflow-y-auto">
                    {pinDetail?.comments?.map((comment, i) => (
                        <div key={i} className="flex gap-2 mt-5 items-center bg-gray-200 rounded-3xl px-3 py-3">
                            <img src={comment.postedBy.image} alt="user-profile" className="w-10 h-10 rounded-full cursor-pointer"/>
                            <div className="flex flex-col">   
                                <p className="font-bold">{comment.postedBy.userName}</p>
                                <p>{comment.comment}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex flex-wrap mt-6 gap-3">
                    <Link to={`/home/user-profile/${user?._id}`}>
                        <img className='w-10 h-10 rounded-full cursor-pointer' src={user?.image} alt='user-profile'/>
                    </Link>
                    <input className="flex-1 border-gray-100 outline-none border-2 p-2 rounded-2xl focus:border-gray-300"
                    type="text"
                    placeholder="Add a comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    
                    />
                    <button type="button" className="bg-red-500 text-white rounded-full px-6 py-2 font-semibold text-base outline-none" onClick={addComment}>
                        {addingComment ? 'Posting comment...' : 'Post'} 
                    </button>
                </div>
             </div>
        </div>
    </div>
        </div>
        {/* <Footer /> */}
        <div className="w-screen" style={{position:"fixed", bottom:"0"}}><NavbarFooter /></div>
        {/* {pins ? (
            <>
                <h2 className="text-center font-bold text-2xl mt-8 mt-4">More Like This</h2>
            </>
        ) : (
            <Spinner message="Loading more pins..."/>
        )} */}
        </>
        
    )
}

export default PinDetail