import React from "react"
import { NavLink, Link, useNavigate } from "react-router-dom"
import { RiHomeFill } from 'react-icons/ri'
import { IoIosArrowForward, IoMdSearch} from 'react-icons/io'
import {BiUserCircle} from 'react-icons/bi'
import logo from '../assets/logo.png'

const isNotActiveStyle = 'flex items-center px-5 gap-3 text-gray-500 hover:text-black transition-all duration-200 ease-in-out capitalize'
const isActiveStyle = 'flex items-center px-5 gap-3 font-extrabold border-r-2 border-black transition-all duration-200 ease-in-out capitalize'

const Sidebar = ({user, closeToggle}) => {

    const handleCloseSidebar = () => {
        if (closeToggle) closeToggle(false);
    
    }
    const navigate = useNavigate()
    return (
        <div className="flex flex-col justify-between h-screen sticky top-0 overflow-y-scroll min-w-210 hide-scrollbar overflow:hidden bg-slate-600">
            <div className="flex flex-col">
                {/* <Link to="/home" className="flex px-5 gap-2 my-6 pt-1 w-190 items-center"
                onClick={handleCloseSidebar}>
                    <img src={user?.image} alt="user" className="w-14 h-12  rounded-full hover:cursor-pointer "  onClick={() => navigate(`/home/user-profile/${user?._id}`)}/>
                </Link> */}
                <div className="flex flex-col pt-6 gap-5">
                    <NavLink to="/home" className={({isActive}) => (isActive ? isActiveStyle : isNotActiveStyle)} onClick={handleCloseSidebar}>
                        <RiHomeFill />
                        Home
                    </NavLink>
                    <NavLink to="/search" className={({isActive}) => (isActive ? isActiveStyle : isNotActiveStyle)} onClick={handleCloseSidebar}>
                        <IoMdSearch  />
                        Search
                    </NavLink>
                    
                    {/* <h3 className="mt-2 px-5 text-base 2xl:text-xl">Discover categories</h3>
                    {categories.slice(0, categories.length - 1).map((category) => (
                        <NavLink to={`/category/${category.name}` } className={(isActive) => isActive ? isActiveStyle : isNotActiveStyle} onClick={handleCloseSidebar} key={category.name}>
                            {category.name}
                        </NavLink>
                    ))} */}
                </div>
                {/* {user && (
                <Link to={`/hom/user-profile/${user._id}`}
                className="flex my-5 mb-3 gap-2 p-2 items-center bg-white rounded-lg shadow-lg mx-3" onClick={handleCloseSidebar}>
                    <img src={user.image} className="w-10 h-10 rounded-4" alt="user-profile"/>
                    <p>{user.userName}</p>
                    <IoIosArrowForward />
                </Link>
            )} */}
            </div>
            
        </div>
    )
}

export default Sidebar