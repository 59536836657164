import React, { useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { client, urlFor } from '../client'
import { v4 as uuidv4 } from 'uuid'
import {MdDownloadForOffline} from 'react-icons/md'
import {AiTwotoneDelete, AiFillHeart, AiOutlineHeart, AiFillAlert, AiOutlineAlert} from 'react-icons/ai'
import {BsFillArrowUpRightCircleFill, BsBookmark, BsFillBookmarkFill} from 'react-icons/bs'
import {TbCircleLetterM, TbCircleLetterT, TbCircleLetterW, TbCircleLetterF, TbCircleLetterS, TbSquareRoundedLetterM, TbSquareRoundedLetterT, TbSquareRoundedLetterW, TbSquareRoundedLetterF, TbSquareRoundedLetterS} from 'react-icons/tb'
import { fetchUser } from '../utils/fetchUser'

const VCPin = ({vcpin: {postedBy, image, _id, save}}) => {
    const [postHovered, setPostHovered] = useState(false)
    const [savingPost, setSavingPost] = useState(false)
    const [show, setShow] = useState(false);
    const [text, setText] = useState('')


    const navigate = useNavigate();
    const user = fetchUser();
    const alreadySaved = !!(save?.filter((item) => item.postedBy._id === user.sub))?.length;

    

    const [value, setValue] = React.useState(0);
    // useEffect(() => {
    //     console.log('count is now', value);
    // }, [value]);
    
    const savePin = (id) => {
        if(!alreadySaved) {
            setSavingPost(true);

            client
                .patch(id)
                .setIfMissing({ save: [] })
                .insert('after', 'save[-1]', [{
                    _key: uuidv4(),
                    userId: user.sub,
                    postedBy: {
                        _type: 'postedBy',
                        _ref: user.sub
                    }
                }])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const unsavePin = (id) => {
        if(alreadySaved) {
            setSavingPost(false);

            client
                .patch(id)
                .unset([`save[-1]`])
                .commit()
                .then(() => {
                    window.location.reload();
                })

        }
    }

    const deletePin = (id) => {
        client
            .delete(id)
            .then(() => {
                window.location.reload();
            })
    }

    return (
        <div className='md-2 mb-6 mr-3 bg-white hover:shadow-lg rounded-lg transition-all duration-500 ease-in-out'
            onMouseEnter={() => setPostHovered(true)}
            onMouseLeave={() => setPostHovered(false)}
        >
            <div
                
                className='relative cursor-pointer w-auto  overflow-hidden  p-3'
                >              
                
                <img className='rounded-lg w-full' alt='user-post' src={urlFor(image).width(250).url()}/> 
                {postHovered && (
                    <div className=' top-0 w-full h-full flex flex-col justify-between  z-50 ' style={{height: '100%'}}>
                        
                        <div className='absolute flex top-0 justify-between items-center gap-2 w-full pl-10 pt-6' style={{left:'140px'}} >
                              
                            {postedBy?._id === user?.sub && (
                                <button type='button' 
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        deletePin(_id);
                                    }}
                                    className='bg-white p-2 opacity-70 hover:opacity-100 font-bold text-dark text-base rounded-3xl hover:shadow-md outlined-none'>
                                        <AiTwotoneDelete />
                                    </button>
                            )}

                        </div>
                    </div>
                )}

            </div>
            {/* <Link to={`/home/user-profile/${postedBy?._id}`} className='flex gap-2 pl-3 mt-2 items-center' >
                <img className='w-8 h-8 rounded-full object-cover' src={postedBy?.image} alt='user-profile'/>
                <p className='font-semibold capitalize'>{postedBy?.userName}</p>
            </Link> */}
            <div className='flex items-center justify-between'>
                            {/* <div className='flex gap-2'>
                                <a
                                    href={`${image?.asset?.url}?dl=`}
                                    download
                                    onClick={(e) => e.stopPropagation()}
                                    className='bg-white w-9 h-9 rounded-full flex items-center justify-center text-dark text-xl opacity-75 hover:opacity-100 hover:shadow-md outline-none'
                                    >
                                    <MdDownloadForOffline />
                                </a>
                            </div> */}
                            
                        </div>
                        {/* <div  className='pl-3'>

                            
                            </div> */}
                        
        </div>
    )
}

export default VCPin



// {alreadySaved ? (
//     <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-2 py-1 text-base rounded-full hover:shadow-md outlined-none'
//     onClick={(e) => {
//         e.stopPropagation()
//         unsavePin(_id)
//         }}>
//         {/* {save?.length}  */}
//         <BsFillBookmarkFill/>
//     </button>
// ) : (
//     <button type='button' className='bg-white opacity-70 hover:opacity-100 text-dark font-bold px-2 py-1 text-base rounded-full hover:shadow-md outlined-none'
//         onClick={(e) => {
//             e.stopPropagation()
//             savePin(_id);
//         }}>
//         <BsBookmark />
//     </button>
// )}  