import React, {useState, useEffect} from "react"
import { AiOutlineLogout } from "react-icons/ai"
import { useParams, useNavigate } from "react-router-dom"
import { userQuery, userCreatedPinsQuery, userSavedPinsQuery, userSavedMondayPinsQuery, userSavedTuesdayPinsQuery, userSavedWednesdayPinsQuery, userSavedThursdayPinsQuery, userSavedFridayPinsQuery, userSavedSaturdayPinsQuery, userSavedSundayPinsQuery  } from "../utils/data"
import { client } from "../client"
import MasonryLayout from "./MasonryLayout"
import Spinner from "./Spinner"
import Navbar from "./Navbar"
import Sidebar from "./Sidebar"
import MobileNavbar from "./MobileNavbar"
import Footer from "./Footer"
import NavbarFooter from "./NavbarFooter"


const activeBtnStyles = 'bg-neutral-300	 text-black font-bold p-3 rounded-full  outline-none'
const notActiveBtnStyles = 'bg-primary text-black font-bold p-3 rounded-full  outline-none'


const UserProfile = ({searchTerm, setSearchTerm}) => {
    const [user, setUser] = useState(null);
    const [pins, setPins] = useState(null);
    const [text, setText] = useState('Monday');
    const [activeBtn, setActiveBtn] = useState('created')

    const navigate = useNavigate();
    const { userId} = useParams();

    useEffect(() => {
        const query = userQuery(userId);

        client.fetch(query)
            .then((data) => {
                setUser(data[0]);
            })
    }, [userId])

    useEffect(() => {
        if(text === 'Created') {
            const createdPinsQuery = userCreatedPinsQuery(userId);

            client.fetch(createdPinsQuery)
                .then((data) => {
                    setPins(data)
                })
        } else if (text === 'Monday') {
            const savedMondayPinsQuery = userSavedMondayPinsQuery(userId);

            client.fetch(savedMondayPinsQuery)
                .then((data) => {
                    setPins(data)
                })
        } else if (text === 'Tuesday') {
            const savedTuesdayPinsQuery = userSavedTuesdayPinsQuery(userId);

            client.fetch(savedTuesdayPinsQuery)
                .then((data) => {
                    setPins(data)
                })
        } else if (text === 'Wednesday') {
            const savedWednesdayPinsQuery = userSavedWednesdayPinsQuery(userId);

            client.fetch(savedWednesdayPinsQuery)
                .then((data) => {
                    setPins(data)
                })
        } else if (text === 'Thursday') {
            const savedThursdayPinsQuery = userSavedThursdayPinsQuery(userId);

            client.fetch(savedThursdayPinsQuery)
                .then((data) => {
                    setPins(data)
                })
        } else if (text === 'Friday') {
            const savedFridayPinsQuery = userSavedFridayPinsQuery(userId);

            client.fetch(savedFridayPinsQuery)
                .then((data) => {
                    setPins(data)
                })
        } else if (text === 'Saturday') {
            const savedSaturdayPinsQuery = userSavedSaturdayPinsQuery(userId);

            client.fetch(savedSaturdayPinsQuery)
                .then((data) => {
                    setPins(data)
                })
        } else if (text === 'Sunday') {
            const savedSundayPinsQuery = userSavedSundayPinsQuery(userId);

            client.fetch(savedSundayPinsQuery)
                .then((data) => {
                    setPins(data)
                })
        }
    }, [text, userId])

    // useEffect(() => {
    //     if(text === 'Created') {
    //         const createdPinsQuery = userCreatedPinsQuery(userId);

    //         client.fetch(createdPinsQuery)
    //             .then((data) => {
    //                 setPins(data)
    //             })
    //     } else {
    //         const savedTuesdayPinsQuery = userSavedTuesdayPinsQuery(userId);

    //         client.fetch(savedTuesdayPinsQuery)
    //             .then((data) => {
    //                 setPins(data)
    //             })
    //     }
    // }, [text, userId])

    // useEffect(() => {
    //     if(text === 'Created') {
    //         const createdPinsQuery = userCreatedPinsQuery(userId);

    //         client.fetch(createdPinsQuery)
    //             .then((data) => {
    //                 setPins(data)
    //             })
    //     } else {
    //         const savedWednesdayPinsQuery = userSavedWednesdayPinsQuery(userId);

    //         client.fetch(savedWednesdayPinsQuery)
    //             .then((data) => {
    //                 setPins(data)
    //             })
    //     }
    // }, [text, userId])

    // useEffect(() => {
    //     if(text === 'Created') {
    //         const createdPinsQuery = userCreatedPinsQuery(userId);

    //         client.fetch(createdPinsQuery)
    //             .then((data) => {
    //                 setPins(data)
    //             })
    //     } else {
    //         const savedThursdayPinsQuery = userSavedThursdayPinsQuery(userId);

    //         client.fetch(savedThursdayPinsQuery)
    //             .then((data) => {
    //                 setPins(data)
    //             })
    //     }
    // }, [text, userId])

    // useEffect(() => {
    //     if(text === 'Created') {
    //         const createdPinsQuery = userCreatedPinsQuery(userId);

    //         client.fetch(createdPinsQuery)
    //             .then((data) => {
    //                 setPins(data)
    //             })
    //     } else {
    //         const savedFridayPinsQuery = userSavedFridayPinsQuery(userId);

    //         client.fetch(savedFridayPinsQuery)
    //             .then((data) => {
    //                 setPins(data)
    //             })
    //     }
    // }, [text, userId])

    // useEffect(() => {
    //     if(text === 'Created') {
    //         const createdPinsQuery = userCreatedPinsQuery(userId);

    //         client.fetch(createdPinsQuery)
    //             .then((data) => {
    //                 setPins(data)
    //             })
    //     } else {
    //         const savedSaturdayPinsQuery = userSavedSaturdayPinsQuery(userId);

    //         client.fetch(savedSaturdayPinsQuery)
    //             .then((data) => {
    //                 setPins(data)
    //             })
    //     }
    // }, [text, userId])

    // useEffect(() => {
    //     if(text === 'Created') {
    //         const createdPinsQuery = userCreatedPinsQuery(userId);

    //         client.fetch(createdPinsQuery)
    //             .then((data) => {
    //                 setPins(data)
    //             })
    //     } else {
    //         const saveSundayPinsQuery = userSavedSundayPinsQuery(userId);

    //         client.fetch(saveSundayPinsQuery)
    //             .then((data) => {
    //                 setPins(data)
    //             })
    //     }
    // }, [text, userId])

  

    return (
        <div>
        <div>
            <div className="bg-gray-50 hidden md:block">
                <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} user={user && user} /> 
            </div>
            <div className="flex bg-gray-50">
                <MobileNavbar />
            </div>
            <div className="text-center mb-7 gap-3 pt-6">                        
                        <button type="button" className={`${activeBtn === 'monday' ? activeBtnStyles : notActiveBtnStyles}`} onClick={(e) => {
                            setText(e.target.textContent);

                            setActiveBtn('monday');
                        }}>Monday</button>
                        <button type="button" className={`${activeBtn === 'tuesday' ? activeBtnStyles : notActiveBtnStyles}`} onClick={(e) => {
                            setText(e.target.textContent);

                            setActiveBtn('tuesday');
                        }}>Tuesday</button>
                        <button type="button"  className={`${activeBtn === 'wednesday' ? activeBtnStyles : notActiveBtnStyles}`} onClick={(e) => {
                            setText(e.target.textContent);

                            setActiveBtn('wednesday');
                        }}>Wednesday</button>
                        <button type="button"  className={`${activeBtn === 'thursday' ? activeBtnStyles : notActiveBtnStyles}`} onClick={(e) => {
                            setText(e.target.textContent);

                            setActiveBtn('thursday');
                        }}>Thursday</button>
                        <button type="button"  className={`${activeBtn === 'Friday' ? activeBtnStyles : notActiveBtnStyles}`} onClick={(e) => {
                            setText(e.target.textContent);

                            setActiveBtn('Friday');
                        }}>Friday</button>
                        <button type="button"  className={`${activeBtn === 'saturday' ? activeBtnStyles : notActiveBtnStyles}`} onClick={(e) => {
                            setText(e.target.textContent);

                            setActiveBtn('saturday');
                        }}>Saturday</button>
                        <button type="button"  className={`${activeBtn === 'sunday' ? activeBtnStyles : notActiveBtnStyles}`} onClick={(e) => {
                            setText(e.target.textContent);

                            setActiveBtn('sunday');
                        }}>Sunday</button>
                    </div>
                    <div className="pl-9 pb-14">
                        {pins?.length ? (
                            <div className="px-2">
                                <MasonryLayout pins={pins} />
                            </div>
                        ) : (
                            <div className="flex justify-center font-bold items-center w-full text-xl mt-2"> No Pins Found </div>
                        )}
                    </div>
        </div>
        {/* <div className="w-screen" style={{position:"fixed", bottom:"0"}}><Footer /></div> */}
        <div className="w-screen" style={{position:"fixed", bottom:"0"}}><NavbarFooter /></div>
        </div>
    )
}

export default UserProfile

