import React, {useState, useEffect} from "react"
import { AiOutlineLogout } from "react-icons/ai"
import { useParams, useNavigate, Link } from "react-router-dom"
import { userQuery, userCreatedVCPinsQuery, userSavedPinsQuery  } from "../utils/data"
import { client } from "../client"
import VCMasonryLayout from "./VCMasonryLayout"
import Spinner from "./Spinner"
import { IoMdAdd, IoMdSearch } from "react-icons/io"
import Navbar from "./Navbar"
import Footer from "./Footer"
import MobileNavbar from "./MobileNavbar"
import NavbarFooter from "./NavbarFooter"
import {AiTwotoneDelete} from 'react-icons/ai'
import VCFeed from "./VCFeed"


// const randomImage = 'https://source.unsplash.com/1600x900/?nature,photography,technology'

// const activeBtnStyles = 'bg-red-500 text-white font-bold p-2 rounded-full w-20 outline-none'
// const notActiveBtnStyles = 'bg-primary mr-4 text-black font-bold p-2 rounded-full w-20 outline-none'


const VirtualCloset = ({ searchTerm, setSearchTerm}) => {
    const [user, setUser] = useState(null);
    const [vcpins, setPins] = useState(null);
    const [text, setText] = useState('Created');
    const [activeBtn, setActiveBtn] = useState('created')



    const navigate = useNavigate();
    const { userId} = useParams();

    useEffect(() => {
        const query = userQuery(userId);

        client.fetch(query)
            .then((data) => {
                setUser(data[0]);
            })
    }, [userId])

    useEffect(() => {
        if(text === 'Created') {
            const createdPinsQuery = userCreatedVCPinsQuery(userId);

            client.fetch(createdPinsQuery)
                .then((data) => {
                    setPins(data)
                })
        } else {
            const savedPinsQuery = userSavedPinsQuery(userId);

            client.fetch(savedPinsQuery)
                .then((data) => {
                    setPins(data)
                })
        }
    }, [text, userId])

    if(!user) {
        return <Spinner message='Loading profile...' />
    }

    const deletePin = (id) => {
        client
            .delete(id)
            .then(() => {
                window.location.reload();
            })
    }


    

    return (
        <div className="relative h-full justify-center items-center mb-6">
            <div className="bg-gray-50 hidden md:block">
                <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} user={user && user} />           
            </div>
            <div className="flex bg-gray-50">
                <MobileNavbar />
            </div>
            <div className="flex gap-2 md:gap-5 w-full mt-5 ">
            {/* <div className="flex justify-start items-center w-full px-2 rounded-md bg-white border-none outline-none focus-within:shadow-sm">
                <IoMdSearch fontSize={21} className="ml-1"/>
                <input 
                    type="text"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search"
                    value={searchTerm}
                    onFocus={() => navigate('/search')}
                    className="p-2 w-full bg-white outline-none"
                />
            </div> */}
            <div className="fixed bottom-0 right-0 m-4 p-4 hidden md:block  z-50">
            
                <Link to='/create-vcpin' className="bg-black text-white rounded-lg w-12 h-12  flex justify-center items-center">
                    <IoMdAdd />
                </Link>
            </div>
            <div className="fixed bottom-0 right-0 m-4 pb-14 lg:hidden xl:hidden 2xl:hidden 3xl:hidden z-50">
            
                <Link to='/create-vcpin' className="bg-black text-white rounded-lg w-12 h-12  flex justify-center items-center">
                    <IoMdAdd />
                </Link>
            </div>
        </div>
            <div className="flex flex-col  pl-9">
                <div className=" flex flex-col mb-7 ">
                    {/* <div className="flex flex-col justify-center items-center">
                        <img className="rounded-full w-20 h-20 -mt-10 shadow-xl object-cover" src={user.image} alt="user-pic"/>
                    </div> */}
                    {/* <div className="text-center mb-7">
                        <button type="button" className={`${activeBtn === 'created' ? activeBtnStyles : notActiveBtnStyles}`} onClick={(e) => {
                            setText(e.target.textContent);

                            setActiveBtn('created');
                        }}>Created</button>
                    </div> */}
                    {vcpins?.length ? (
                        <div className="px-2" >
                            <VCMasonryLayout vcpins={vcpins} style={{ pointerEvents: 'none' }}/>
                        </div>
                    ) : (
                        <div className="flex justify-center font-bold items-center w-full text-xl mt-2"> No Pins Found </div>
                    )}
                    {/* <div className="pl-6 overflow">
                    <VCFeed />
                </div>  */}
           
                </div>
                {/* {postedBy?._id === user?.sub && (
                                <button type='button' 
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        deletePin(_id);
                                    }}
                                    className='bg-white p-2 opacity-70 hover:opacity-100 font-bold text-dark text-base rounded-3xl hover:shadow-md outlined-none'>
                                        <AiTwotoneDelete />
                                    </button>
                            )} */}
            </div>
            {/* <div className="w-screen" style={{position:"fixed", bottom:"0"}}><Footer /></div> */}
            <div className="w-screen" style={{position:"fixed", bottom:"0"}}><NavbarFooter /></div>
        </div>
    )
}

export default VirtualCloset