import React, { useRef } from "react";
import emailjs from '@emailjs/browser'
import Navbar from "./Navbar";
import MobileNavbar from "./MobileNavbar";
import Footer from "./Footer";
import NavbarFooter from "./NavbarFooter";

const ContactForm = ({user, searchTerm, setSearchTerm}) => {

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_9pq2bjq', 'template_bztduos', form.current, 'Fnc4hDaxQbfdUl_Qp')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <div>
            <div className="bg-gray-50 hidden md:block">
                <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} user={user && user} />     
            </div>   
            <div className="flex bg-gray-50">
                <MobileNavbar />
            </div>   
            <div>       
                <form ref={form} onSubmit={sendEmail} className="flex justify-center items-center h-screen flex-col p-3 w-sreen">
                        <label className="text-3xl pb-3">Contact Us</label>
                        <label className="pb-1">Name</label>
                        <input type="text" name="from_name" className="border-2 border-black" />
                        <label className="pt-3 pb-1">Email</label>
                        <input type="email" name="from_email" className="border-2 border-black"/>
                        <label className="pt-3 pb-1">Message</label>
                        <textarea name="message" className="border-2 border-black"/>
                        <input className="pt-3" type="submit" value="Send" />
                </form>
            </div>
            <Footer />
            <div className="w-screen" style={{position:"fixed", bottom:"0"}}><NavbarFooter /></div>
        </div>
    )
}

export default ContactForm