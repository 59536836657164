import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {BiHomeAlt2} from "react-icons/bi"
import {SlNotebook} from "react-icons/sl"
import { TbLetterS } from "react-icons/tb";
import { BiCloset } from "react-icons/bi";
import { AiOutlineSetting } from "react-icons/ai";
import { userQuery } from "../utils/data";
import { client } from "../client";
import { fetchUser } from "../utils/fetchUser";

const NavbarFooter = () => {

    const navigate = useNavigate()
  const userInfo = fetchUser();
  const [user, setUser] = useState()
  const [searchTerm, setSearchTerm] = useState('');


  useEffect(() => {
    const query = userQuery(userInfo?.sub)

    client.fetch(query)
        .then((data) => {
            setUser(data[0])
        })
}, []);

    return (
        <div className="lg:hidden md:hidden" >
            <div className="flex relative fixed bg-slate-50 flex-row justify-center items-center text-center gap-10" style={{height:"60px"}}>
            <div className="flex flex-row justify-center items-center text-center gap-10">
                <Link to="/looks"><BiHomeAlt2 /></Link>
                <Link to={`/home/virtual-closet/${user?._id}`}><BiCloset/></Link>
                <Link to={`/home/weekly-planner/${user?._id}`}><SlNotebook/></Link>
                <Link to="/home"><TbLetterS/></Link>
                <Link to="/settings"><AiOutlineSetting /></Link>
            </div>
                
                
            </div>
        </div>
    )
}

export default NavbarFooter