import React, {useState, useEffect} from 'react'
import MasonryLayout from './MasonryLayout'
import { client } from '../client'
import {feedQuery, searchQuery} from '../utils/data'
import Spinner from './Spinner'
import { IoMdAdd, IoMdSearch } from 'react-icons/io'
import { Link, useNavigate } from 'react-router-dom'
import Navbar from './Navbar'
import Sidebar from './Sidebar'

const Search = ({user, searchTerm, setSearchTerm}) => {

    const [pins, setPins] = useState(null)
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate();

    useEffect(() => {
        if(searchTerm) {
            setLoading(true);
            const query = searchQuery(searchTerm.toLowerCase());

            client.fetch(query)
                .then((data) => {
                    setPins(data);
                    setLoading(false);
                })
        } else {
            client.fetch(feedQuery)
                .then((data) => {
                    setPins(data);
                    setLoading(false);
                })
        }
    }, [searchTerm])

    return (
        <div>
            <div className="bg-gray-50">
                <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} user={user && user} />
            </div>
            <div className="min-h-screen flex">
                {/* <div className="flex">
                    <Sidebar />
                </div> */}
                <div className='flex-1 '>
                {/* <div className="flex justify-start items-center mt-6 w-full rounded-md bg-white border-none outline-none focus-within:shadow-sm">
                    <IoMdSearch fontSize={21} className="ml-1"/>
                    <input 
                        type="text"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search"
                        value={searchTerm}
                        onFocus={() => navigate('/search')}
                        className="p-2 w-full bg-white outline-none"
                    />
                </div> */}
                <div className="flex gap-2 md:gap-5 w-full mt-5 pb-7 pl-6">
            <div className="flex justify-start items-center w-full px-2 rounded-md bg-white border-none outline-none focus-within:shadow-sm">
                <IoMdSearch fontSize={21} className="ml-1"/>
                <input 
                    type="text"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search"
                    value={searchTerm}
                    onFocus={() => navigate('/search')}
                    className="p-2 w-full bg-white outline-none"
                />
            </div>
            <div className="flex gap-3 pr-3">
                <Link to='/create-pin' className="bg-black text-white rounded-lg w-12 h-12 md:w-14 md:h-12 flex justify-center items-center">
                    <IoMdAdd />
                </Link>
                
            </div>
        </div>
                <div className='pt-6 px-6'>
                    {loading && <Spinner message="Searching for pins..." />}
                    {pins?.length !== 0 && <MasonryLayout pins={pins}/>}
                    {pins?.length == 0 && searchTerm !== '' && !loading && (
                        <div className='mt-10 text-center text-xl'>No Pins Found</div>
                    )}
                </div>
            </div>
            </div>
        </div>
    )
}

export default Search