import React, {useState, useEffect} from "react"
import { AiOutlineLogout } from "react-icons/ai"
import { useParams, useNavigate } from "react-router-dom"
import { userQuery, userCreatedPinsQuery, userSavedPinsQuery  } from "../utils/data"
import { client } from "../client"
import MasonryLayout from "./MasonryLayout"
import Spinner from "./Spinner"
import Navbar from "./Navbar"
import Sidebar from "./Sidebar"
// import "./SplitScreen.css"
import Footer from "./Footer"
import NavbarFooter from "./NavbarFooter"
import MobileNavbar from "./MobileNavbar"

const randomImage = 'https://source.unsplash.com/1600x900/?nature,photography,technology'

const activeBtnStyles = 'bg-neutral-300 text-black font-bold p-2 rounded-full w-20 outline-none'
const notActiveBtnStyles = 'bg-primary text-black font-bold p-2 rounded-full w-20 outline-none'


const UserProfile = ({searchTerm, setSearchTerm}) => {
    const [user, setUser] = useState(null);
    const [pins, setPins] = useState(null);
    const [text, setText] = useState('Created');
    const [activeBtn, setActiveBtn] = useState('created')

    function handleSignout() {
        localStorage.clear()
        navigate('/')
    }

    const navigate = useNavigate();
    const { userId} = useParams();

    useEffect(() => {
        const query = userQuery(userId);

        client.fetch(query)
            .then((data) => {
                setUser(data[0]);
            })
    }, [userId])

    useEffect(() => {
        if(text === 'Created') {
            const createdPinsQuery = userCreatedPinsQuery(userId);

            client.fetch(createdPinsQuery)
                .then((data) => {
                    setPins(data)
                })
        } else {
            const savedPinsQuery = userSavedPinsQuery(userId);

            client.fetch(savedPinsQuery)
                .then((data) => {
                    setPins(data)
                })
        }
    }, [text, userId])

    if(!user) {
        return <Spinner message='Loading profile...' />
    }

    return (
        <div className="relative justify-center items-center ">
            <div className="bg-gray-50 hidden md:block">
                <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} user={user && user} />
            </div>
            <div className="flex bg-gray-50">
            <MobileNavbar />
        </div>
            <div className="min-h-screen flex ">
                {/* <div className="flex hidden md:block ">
                    <Sidebar />
                </div> */}
                <div className=" flex min-h-full">
                    <div className="flex ">
                        <div className="flex flex-col">
                            <div className="relative flex flex-col">
                                <div className="flex flex-col justify-center items-center ">
                                    <img src={randomImage}
                                        className="w-screen h-370 2xl:h-510 shadow-lg object-cover" alt="banner-pic"/>
                                    <img className="rounded-full w-20 h-20 -mt-10 shadow-xl object-cover" src={user.image} alt="user-pic"/>
                                    <h1 className="font-bold text-3xl text-center mt-3">{user.userName}</h1>
                                    <div className="absolute top-0 z-1 right-0 p-2">
                                
                                        {userId === user._id && ( 
                                            <button className="bg-red-500 text-white font-bold p-2 rounded-full w-20 outline-none" onClick={handleSignout}>Logout</button>
                                        )}
                                    </div>
                                </div>
                                <div className="text-center mb-7 mt-7 gap-3">
                                    <button type="button" className={`${activeBtn === 'created' ? activeBtnStyles : notActiveBtnStyles}`} onClick={(e) => {
                                    setText(e.target.textContent);

                                    setActiveBtn('created');
                                    }}>Created</button>
                                    <button type="button" className={`${activeBtn === 'saved' ? activeBtnStyles : notActiveBtnStyles}`} onClick={(e) => {
                                    setText(e.target.textContent);

                                    setActiveBtn('saved');
                                    }}>Saved</button>
                                </div>
                                <div className="pl-6">
                                    {pins?.length ? (
                                    <div className="px-2">
                                        <MasonryLayout pins={pins} />
                                    </div>
                                ) : (
                                    <div className="flex justify-center font-bold items-center w-full text-xl mt-2"> No Pins Found </div>
                                        )}
                                </div>  
                                {/* <Footer />       */}
                            </div>
                    
                            <div className="w-screen" style={{position:"fixed", bottom:"0"}}><NavbarFooter /></div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default UserProfile

