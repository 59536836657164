import React from 'react'
import Masonry from 'react-masonry-css'
import VCPin from './VCPin'

const breakpointObj = {
    default: 4,
    3000: 6,
    2000: 5,
    1200: 3,
    1000: 2,
    500: 1,
}

const VCMasonryLayout = ({vcpins}) => {
    return (
        <Masonry className='flex animate-slide-fwd pr-6 gap-1' breakpointCols={breakpointObj}>
            {vcpins?.map((vcpin) => <VCPin key={vcpin._id} vcpin={vcpin} className='w-max' />)}
        </Masonry>
    )
}

export default VCMasonryLayout